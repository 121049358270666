
<template>
  <router-view></router-view>
</template>

<script>
export default {

}
</script>

<style>

.page{
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

</style>
