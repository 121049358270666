/*
 * @Descripttion: js
 * @Author: ymj
 */
/*
 * @Descripttion: js
 * @Author: ymj
 */
/*
 * @Descripttion: js
 * @Author: ymj
 */
import { createStore } from 'vuex'


const store = createStore({
  state: {
    token: ''
  }
})


export default store