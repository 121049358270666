/*
 * @Descripttion: js
 * @Author: ymj
 */
import { createWebHashHistory, createRouter } from "vue-router"


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      component: () => import('@/pages/index.vue')
    },
    {
      path: '/register',
      component: () => import('@/pages/register.vue')
    },
  
  ]
})

export default router